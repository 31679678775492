import React, {useEffect, useState } from "react";
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";

export const LoggedInWithRoleAccess = ({ children, roles, error }) => {
  const { instance } = useMsal();
  const [userRoles, setUserRoles] = useState();

  useEffect(() => {
    const currentUser = instance.getAllAccounts()[0];
    if (currentUser) {
      setUserRoles(getUserRoles(currentUser));
    }
  }, [instance]);

  if (!userRoles) {
    return null;
  }

  const allow = roles.some((r) => userRoles?.indexOf(r) >= 0);

  return allow ? (
    <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
  ) : error ? (
    <div>{error}</div>
  ) : null;
};

const getUserRoles = (currentUser) => {
  if (currentUser) {
    if (currentUser?.idTokenClaims?.roles) {
      return currentUser?.idTokenClaims?.roles;
    } else {
      return [];
    }
  } else {
    return undefined;
  }
};
