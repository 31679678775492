import React, { useEffect, useState } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/user-panel";
import "./header.scss";
import { Template } from "devextreme-react/core/template";
import logo from "./nhsLogoLeft.png";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
let addDevColour = "";

const testSystem = process.env.REACT_APP_DEVMODE;
const hasAccess = window.sessionStorage.getItem("atd");

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  
  if (testSystem === "true") {
    addDevColour = "devBanner";
    title = `${title} | Test System`;
  }

  return (
    <>
      <AuthenticatedTemplate>
        <header className={"header-component"}>
          <Toolbar className={`header-toolbar ${addDevColour}`}>
              <Item
                visible={menuToggleEnabled}
                location={"before"}
                widget={"dxButton"}
                cssClass={"menu-button"}
              >
                <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
              </Item>
            <Item
              location={"before"}
              cssClass={"header-title"}
              text={title}
              visible={!!title}
            />
            <Item
              location={"after"}
              locateInMenu={"auto"}
              menuItemTemplate={"userPanelTemplate"}
            >
              <div className={"user-con"}>
                <UserPanel menuMode={"context"} />
              </div>
            </Item>
            <Item location={"after"} cssClass={"header-logo-img"}>
              <img src={logo} alt={"NHS-Logo"} />
            </Item>
            <Template name={"userPanelTemplate"}>
              <UserPanel menuMode={"list"} />
            </Template>
          </Toolbar>
        </header>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <header className={"header-component"}>
          <Toolbar className={`header-toolbar ${addDevColour}`}>
            <Item location={"before"} cssClass={"menu-button"}></Item>
            <Item
              location={"before"}
              cssClass={"header-title"}
              text={title}
              visible={!!title}
            />
            <Item
              location={"after"}
              locateInMenu={"auto"}
              menuItemTemplate={"userPanelTemplate"}
            >
              <div className={"user-con"}>
                <UserPanel menuMode={"context"} />
              </div>
            </Item>
            <Item location={"after"} cssClass={"header-logo-img"}>
              <img src={logo} alt={"NHS-Logo"} />
            </Item>
            <Template name={"userPanelTemplate"}>
              <UserPanel menuMode={"list"} />
            </Template>
          </Toolbar>
        </header>
      </UnauthenticatedTemplate>
    </>
  );
}
