import React, { useState } from "react";

import { useMsal } from "@azure/msal-react";

import { callAPi, getAccessToken } from "../api/api";

const useFetch = () => {
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const baseUrl = process.env.REACT_APP_APIURL;
  const code = process.env.REACT_APP_APICODE;
  
  const apiFetch = async (url, method = "GET", params) => {
    setIsLoading(true);

    try {
      const token = await getAccessToken(instance);

      const result = await callAPi(
        token,
        baseUrl + url + code,
        method,
        params
      ).then((res) => {
        if (res) {
          setHasError(false);
          return res;
        }

        throw new Error(`Something went wrong`);
      });

      setIsLoading(false);

      return result;
    } catch (e) {
      setIsLoading(false);
      setHasError(true);
    }
  };

  return { apiFetch, isLoading, hasError };
};

export default useFetch;
