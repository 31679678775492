export const navigation = [
  {
    text: 'Diaries Home',
    path: '/home',
    icon: 'event'
  },
  {
    text: 'Lists Home',
    path: '/listshome/',
    icon: 'rowfield'
  },
  {
    text: 'Audit Home',
    path: '/audithome/',
    icon: 'checklist'
  },
];
