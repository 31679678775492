import React, { useState, useRef, useEffect, useCallback } from "react";
import "./audit.scss";

//get api hook
import useFetch from "../../hooks/useFetch";
import { useLoading } from "../../hooks/LoadingContext";

//get dx form inputs
import { Button } from "devextreme-react/button";
import { LoadIndicator } from "devextreme-react/load-indicator";

//dates to manipulated
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";

//get app params (url)
import { useParams } from "react-router";

//route history
import { useHistory } from "react-router-dom";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Summary,
  TotalItem,
  Export,
  Grouping,
  Selection,
  Toolbar,
  ColumnChooser,
  ColumnFixing,
} from "devextreme-react/data-grid";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Item, Label } from "devextreme-react/form";
import dxDataGrid from "devextreme/ui/data_grid";
import { FilterRow } from "devextreme-react/gantt";
import { FilterPanel } from "devextreme-react/tree-list";
import { Form } from "react-bootstrap";
import { Input } from "@material-ui/core";
import { event } from "jquery";

let errorMessage = null;
const min = subDays(new Date(), 15);
const max = addDays(new Date(), 30);
const minDate = format(new Date(min), "yyyy-MM-dd");
const maxDate = format(new Date(max), "yyyy-MM-dd");
const today = format(new Date(), "yyyy-MM-dd");
const currentDate = format(new Date(), "dd-MM-yyyy");

const Audit = () => {
  const { apiFetch } = useFetch();
  const { loading, setLoading } = useLoading(false);
  const [data, setData] = useState();
  const params = useParams();
  const [error, setError] = useState(false);
  const history = useHistory();
  const [showPatientDetails, setShowPatientDetails] = useState();
  const [patientDetails, setPatientDetails] = useState();
  const [patientNotes, setPatientNotes] = useState();
  const [patientAddress, setPatientAddress] = useState();
  const dataGridRef = useRef(null);
  const [dateTo, setDateTo] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [allowedAudit, setAllowedAudit] = useState(false);
  const [passVal, setPassVal] = useState();
  const [errorPhrase, setErrorPhrase] = useState("");

  useEffect(() => {
    //getAllAppointments();
  }, []);

  const getAllAppointments = async (dateTo, dateFrom) => {
    setLoading(true);
    if (params.diaryId === ":diaryId") {
      setError(true);
      errorMessage = (
        <div className={"content-block"}>
          <h5 style={{ width: "100%", margin: "auto" }}>
            Please select a diary list from the list home page.
          </h5>
        </div>
      );
      setLoading(false);
    } else {
      const result = await apiFetch("get-diary", "GET", {
        startDate: dateFrom,
        endDate: dateTo,
        diaryId: params.diaryId,
      });

      if (result) {
        setData(result);
        setLoading(false);
      } else {
        setError(true);
        errorMessage = (
          <div className={"content-block"}>
            <h5 style={{ width: "100%", margin: "auto" }}>
              Error retrieving appointments please try again later or contact
              your system administrator.
            </h5>
          </div>
        );
      }
    }
  };

  const routeChange = () => {
    let path = `/audithome`;
    history.push(path);
  };

  const pageSizes = [10, 25, 50, 100];

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main Sheet");

    exportDataGrid({
      component: dataGridRef.current.instance,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${data.name}_${currentDate}.xlsx`
        );
      });
    });
    e.cancel = true;
  };

  const handleChangeDateTo = (event) => {
    setDateTo(event.target.value);
  };

  const handleChangeDateFrom = (event) => {
    setDateFrom(event.target.value);
  };

  const onDateChange = () => {
    getAllAppointments(dateTo, dateFrom);
  };

  const handleChangeAuditPass = (event) => {
    setPassVal(event.target.value);
    setErrorPhrase("");
  };

  const onPassChange = () => {
    if (passVal === undefined) {
      setErrorPhrase("Invalid pass phrase entered");
    } else {
      const encoded = Buffer.from(passVal).toString("base64");
      if (params.phrase === encoded) {
        setAllowedAudit(true);
        setErrorPhrase("");
      } else {
        setErrorPhrase("Invalid pass phrase entered");
      }
    }
  };

  const onSelection = (props) => {
    const selected = props.selectedRowsData[0];
    let dob = format(new Date(selected.dob), "dd/MM/yyyy");
    let patient = [
      { id: "TNumber", content: selected.tNumber },
      { id: "NHSNumber", content: selected.nhsNumber },
      { id: "Fullname", content: `${selected.firstName} ${selected.surName}` },
      { id: "D.O.B", content: dob },
    ];
    let address = [
      { id: "Address Line 1", content: selected.addLine1 },
      { id: "Address Line 2", content: selected.addLine2 },
      { id: "Town City", content: selected.townCity },
      { id: "Postcode", content: selected.postcode },
      { id: "Contact Number", content: selected.contactNumber },
    ];
    let notes = [selected.notesField];
    setShowPatientDetails(true);
    setPatientDetails(patient);
    setPatientAddress(address);
    setPatientNotes(notes);
  };

  const showColumnChooser = (e) => {
    dataGridRef.current.instance.showColumnChooser();
  };

  return (
    <>
      <div className={"content-block"}>
        <div className={"title-con"}>
          <h2 className={"title-left"}>{data && data.name}</h2>
          <div className={"title-right"}>
            <Button
              icon={"rowfield"}
              onClick={routeChange}
              text={"Audit Home"}
            />
          </div>
        </div>
      </div>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings diary-main"}>
          {loading ? (
            <div className="loading">
              <div className="loader-icon">
                <LoadIndicator id="large-indicator" height={60} width={60} />
              </div>
            </div>
          ) : (
            <>
              {error ? (
                <div>{errorMessage}</div>
              ) : (
                <>
                  {allowedAudit ? (
                    <>
                      <div className={"test"}>
                        <div style={{ textAlign: "left", margin: "1rem" }}>
                          <label style={{ margin: "1rem", fontSize: "1rem" }}>
                            From:{" "}
                            <input
                              type="date"
                              id="dateFrom"
                              onChange={handleChangeDateFrom}
                              style={{padding: "0.3rem"}}
                            />
                          </label>
                          <label style={{ margin: "1rem", fontSize: "1rem" }}>
                            To:{" "}
                            <input
                              type="date"
                              id="dateTo"
                              onChange={handleChangeDateTo}
                              style={{padding: "0.3rem"}}
                            />
                          </label>
                          <Button type={"success"} onClick={onDateChange} className={"ml-auto btn btn-secondary"}>
                            Submit
                          </Button>
                        </div>
                      </div>
                      <DataGrid
                        ref={dataGridRef}
                        id={"gridContainer"}
                        dataSource={data && data.appointments}
                        keyExpr={data && data.startDate}
                        columnAutoWidth={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                        onSelectionChanged={onSelection}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                      >
                        <FilterRow visible={true} />
                        <FilterPanel visible={false} />
                        <Selection mode={"single"} />
                        <ColumnChooser enabled={true} />
                        <ColumnFixing enabled={true} />
                        <Column
                          dataField={"startDate"}
                          dataType={"datetime"}
                          format={"dd/MM/yyyy HH:mm:ss"}
                          caption={"Date/Time"}
                          fixed={true}
                        />
                        <Column
                          dataField={"tNumber"}
                          dataType={"string"}
                          caption={"T No"}
                        />
                        <Column
                          dataField={"nhsNumber"}
                          dataType={"string"}
                          caption={"NHS No"}
                        />
                        <Column
                          dataField={"firstName"}
                          dataType={"string"}
                          caption={"Forename"}
                        />
                        <Column
                          dataField={"surName"}
                          dataType={"string"}
                          caption={"Surname"}
                        />
                        <Column
                          dataField={"dob"}
                          dataType={"date"}
                          caption={"D.O.B"}
                          format={"dd/MM/yyyy"}
                        />
                        <Column
                          dataField={"rAttend"}
                          dataType={"string"}
                          caption={"Reason for attendance"}
                        />
                        <Column
                          dataField={"addedToPas"}
                          dataType={"boolean"}
                          caption={"Added To PAS"}
                        />
                        <Column
                          dataField={"addedBy"}
                          dataType={"string"}
                          caption={"Added By"}
                        />
                        <Column
                          dataField={"createdBy.date"}
                          dataType={"datetime"}
                          format={"dd/MM/yyyy HH:mm:ss"}
                          caption={"Created Date/Time"}
                        />
                        <Column
                          dataField={"changedBy"}
                          dataType={"string"}
                          caption={"Updated By"}
                        />
                        <Column
                          dataField={"updatedBy.date"}
                          dataType={"datetime"}
                          format={"dd/MM/yyyy HH:mm:ss"}
                          caption={"Updated Date/Time"}
                        />
                        <Column
                          dataField={"notesField"}
                          dataType={"string"}
                          caption={"Notes"}
                          width={100}
                        />
                        <Column
                          dataField={"owner"}
                          dataType={"string"}
                          caption={"Owner"}
                          visible={false}
                        />
                        <Column
                          dataField={"fTriage"}
                          dataType={"string"}
                          caption={"Triage"}
                          visible={false}
                        />
                        <Column
                          dataField={"wRefer"}
                          dataType={"string"}
                          caption={"Referrer"}
                          visible={false}
                        />
                        <Column
                          dataField={"obs"}
                          dataType={"string"}
                          caption={"OBS"}
                          visible={false}
                        />
                        <Column
                          dataField={"ecg"}
                          dataType={"string"}
                          caption={"ECG"}
                          visible={false}
                        />
                        <Column
                          dataField={"sBloods"}
                          dataType={"string"}
                          caption={"Specific Bloods"}
                          visible={false}
                        />
                        <Column
                          dataField={"addLine1"}
                          dataType={"string"}
                          caption={"Address Line 1"}
                          visible={false}
                        />
                        <Column
                          dataField={"addLine2"}
                          dataType={"string"}
                          caption={"Address Line 2"}
                          visible={false}
                        />
                        <Column
                          dataField={"townCity"}
                          dataType={"string"}
                          caption={"City/Town"}
                          visible={false}
                        />
                        <Column
                          dataField={"postcode"}
                          dataType={"string"}
                          caption={"Postcode"}
                          visible={false}
                        />
                        <Column
                          dataField={"contactNumber"}
                          dataType={"string"}
                          caption={"Contact Number"}
                          visible={false}
                        />
                        <Summary>
                          <TotalItem column={"startDate"} summaryType="count" />
                        </Summary>
                        <Pager
                          allowedPageSizes={pageSizes}
                          showPageSizeSelector={true}
                        />
                        <Paging defaultPageSize={10} />
                        <Toolbar>
                          <Item location={"before"}>
                            <p>Please select a row to see more information.</p>
                          </Item>
                          <Item location={"after"}>
                            <Button
                              icon={"exportxlsx"}
                              onClick={onExporting}
                            ></Button>
                          </Item>
                          <Item location={"after"}>
                            <Button
                              icon={"columnchooser"}
                              onClick={showColumnChooser}
                            ></Button>
                          </Item>
                        </Toolbar>
                        <Export enabled={true} />
                      </DataGrid>
                    </>
                  ) : (
                    <>
                      <div>
                        <h6 style={{ textAlign: "left", margin: "1rem" }}>
                          Please provide diary passphrase.
                        </h6>
                        <div className={"passphrase"}>
                          <div style={{ textAlign: "left", margin: "1rem" }}>
                              <label style={{ margin: "1rem", fontSize: "1rem"}}>
                              Passphrase:{" "}
                              <input
                                type="password"
                                id="auditPass"
                                onChange={handleChangeAuditPass}
                                autoComplete="off"
                                style={{padding: "0.3rem" }}
                              />
                              </label>
                            <Button type={"success"} onClick={onPassChange} className={"ml-auto btn btn-secondary"}>
                              Submit
                            </Button>
                          </div>
                          <div>
                            <p
                              style={{
                                textAlign: "left",
                                margin: "2rem",
                                color: "red",
                              }}
                            >
                              {errorPhrase ? errorPhrase : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {showPatientDetails && (
                    <div className={"patientDetailsFooter"}>
                      <div className={"patient"}>
                        <h6>Patient Details</h6>
                        <div className={"details"}>
                          {patientDetails &&
                            patientDetails.map((item) => (
                              <div key={item.id}>
                                <p style={{ fontWeight: "bold" }}>{item.id}</p>
                                <p>{item.content}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className={"address"}>
                        <h6>Patient Address</h6>
                        <div className={"details"}>
                          {patientAddress &&
                            patientAddress.map((item) => (
                              <div key={item.id}>
                                <p style={{ fontWeight: "bold" }}>{item.id}</p>
                                <p>{item.content}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className={"notes"}>
                        <h6>Notes</h6>
                        <p>{patientNotes}</p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Audit;
