export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTHAPPID,
    authority:
      "https://login.microsoftonline.com/e881ccf4-275a-4138-baa6-6ff2b3fe77c2", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "/",
    navigateToLoginRequestUrl: false,
    //redirectUri: "http://localhost:3000",
    //redirectUri: "https://white-sea-0ba24b303.azurestaticapps.net/" -- deployed test
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_AUTHSCOPE],
  prompt: "login",
};
