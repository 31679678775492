import { loginRequest } from "../authConfig";

export const callAPi = async (token, url, method = "GET", params = null) => {
  try {
    const headers = new Headers();
    const bearer = `Bearer ${token.accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const options = {
      method: method,
      headers: headers,
    };

    if (params) {
      if (method === "GET") {
        url += "&" + objectToQueryString(params);
      } else if (method === "POST" || method === "PUT") {
        options.body = JSON.stringify(params); // body should match Content-Type in headers option
      }
    }

    return fetch(url, options)
      .then((res) => {
        if (res.status === 204 || res.status === 404) {
          return null;
        }
        if (res.ok) {
          return res.json();
        }

        throw new Error("Something went wrong.");
      })
      .catch((error) => console.log(error));
  } catch (e) {}
};

export const getAccessToken = async (msalInstance) => {
  const accounts = msalInstance.getAllAccounts();

  const tokenResponse = await msalInstance.acquireTokenSilent({
    scopes: loginRequest.scopes,
    account: accounts[0],
  });

  return tokenResponse;
};

function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
}
