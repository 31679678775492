import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { custom } from "devextreme/ui/dialog";
import "./home.scss";
import notify from "devextreme/ui/notify";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { useLoading } from "../../hooks/LoadingContext";

const testSystem = process.env.REACT_APP_DEVMODE;

export default function Home() {
  const { apiFetch } = useFetch();
  const [diaryList, setDiaryList] = useState();
  const [error, setError] = useState(false);
  const { loading, setLoading } = useLoading(false);

  useEffect(() => {
    getAllDiaries();
  }, []);

  const getAllDiaries = async () => {
    setLoading(true);
    const result = await apiFetch("get-diaries");
    if (result) {
      setDiaryList(result);
      setLoading(false);
    } else {
      setError(true);
      showToast(
        "Error",
        `Error.. unable to retrieve diary list from API please try again later...`,
        "error"
      );
      setLoading(false);
    }

    if (testSystem === "true") {
      if (result.length > 0) {
        window.sessionStorage.setItem("atd", "true");
        if (window.sessionStorage.getItem("agreed") !== "true") {
          const alertConfirm = custom({
            dragEnabled: false,
            width: "50vw",
            title: "Welcome to the Admissions Diary Test System",
            messageHtml:
              "<i>By agreeing you are aware that you will not use any live patient data and the data stored in this test system will be cleared/changed at any given time.</i>",
            buttons: [
              {
                text: "I understand",
                onClick: (e) => {
                  return { buttonText: e.component.option("text") };
                },
              },
            ],
          });

          alertConfirm.show().then((dialogResult) => {
            window.sessionStorage.setItem("agreed", "true");
          });
        }
      }
    }
  };

  const showToast = (event, value, type) => {
    notify(`${event} "${value}"`, type, 7500);
  };

  return (
    <>
      <h2 className={"content-block"}>Home</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"logos-container"}>
            <h5>Welcome to the diary application</h5>
            {loading ? (
              <>
                <p>Please wait while we check the diary list.</p>
                <div className="loading-home">
                  <div className="loader-icon-home">
                    <LoadIndicator
                      id="small-indicator"
                      height={30}
                      width={30}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {error ? (
                  <div className={"info"}>
                    <p>Error loading diary list</p>
                  </div>
                ) : (
                  <>
                    {diaryList && (
                      <>
                        {diaryList && diaryList.length > 0 ? (
                          <div className={"content-block"}>
                            <p>Please pick the diary from below</p>
                            <p>
                              If you require access to another admissions diary
                              please log a call with service desk with the
                              desired admissions diary you require.
                            </p>
                            <div className={"tile-group"}>
                              {diaryList.map((diary) => {
                                return (
                                  <Link
                                    key={diary.id}
                                    to={`/diary/${diary.id}`}
                                    className={"card"}
                                  >
                                    <div>
                                      <div className={"card-title"}>
                                        <i
                                          className="dx-icon-event"
                                          style={{ "fontSize": "1.5rem" }}
                                        ></i>
                                      </div>
                                      <div className={"card-body"}>
                                        {diary.name}
                                      </div>
                                    </div>
                                  </Link>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <div className={"content-block"}>
                            <p>
                              No diaries found..
                              <br />
                              <br />
                              If you require access to another admissions diary
                              please log a call with service desk with the
                              desired admissions diary you require.
                            </p>
                            <div className={"tile-group"}></div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
