import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useState,
} from "react";
import TreeView from "devextreme-react/tree-view";
import { navigation } from "../../app-navigation";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import "./side-navigation-menu.scss";

import * as events from "devextreme/events";
import useFetch from "../../hooks/useFetch";

export default function SideNavigationMenu(props) {
  const [showMenu, setMenuShow] = useState(false);
  const { apiFetch } = useFetch();
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;
  const { isLarge } = useScreenSize();

  useEffect(() => {
    getAllDiaries();
  }, []);

  const getAllDiaries = async () => {
    const result = await apiFetch("get-diaries");
    if (result) {
      if (result.length > 0) {
        setMenuShow(true);
      } else {
        setMenuShow(false);
      }
    } else {
      setMenuShow(false);
    }
  };

  function normalizePath() {
    return navigation.map((item) => {
      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }
      return { ...item, expanded: isLarge };
    });
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      {children}
      <div className={"menu-container"}>
        {showMenu ? (
          <TreeView
            ref={treeViewRef}
            items={items}
            keyExpr={"path"}
            selectionMode={"single"}
            focusStateEnabled={false}
            expandEvent={"click"}
            onItemClick={selectedItemChanged}
            onContentReady={onMenuReady}
            width={"100%"}
          />
        ) : null}
      </div>
    </div>
  );
}
