import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../authConfig";
import Button from "react-bootstrap/Button";
import logo from "../components/header/40c1689e6145318070fd.svg";
import divider from "../components/header/divider.png";
import appInfo from "../app-info";

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance } = useMsal();
  const [loginHint, setLoginHint] = useState("");

  const handleChange = (event) => {
    setLoginHint(event.target.value);
  };

  const signIn = async (loginHint) => {
    await instance.handleRedirectPromise();
    instance.loginRedirect({
      scopes: loginRequest.scopes,
      prompt: "login",
      loginHint: loginHint
        ? `${loginHint}@northumbria-healthcare.nhs.uk`
        : undefined,
      domainHint: "northumbria-healthcare.nhs.uk",
    });
  };

  return (
    <div className="m-2" style={{ width: "auto" }}>
      <img src={logo} alt={"NHS-Logo"} height={"50px"} />
      <img src={divider} alt={"Divider"} height={"50px"} />
      <span style={{ fontSize: "1.5rem", height: "50px", margin: "auto" }}>
        {appInfo.title}
      </span>
      <hr className="m-3" style={{ border: "1px solid rgb(56 57 57)", width: "auto" }}>
      </hr>
      <p style={{ textAlign: "left", width: "auto" }}>
        Please enter the first part of your email address or username to access the system.
      </p>
      <form className="row g-3 p-3" style={{ width: "auto" }} onSubmit={e => {e.preventDefault(); }}>
          <div className="col-sm-5 pe-0">
            <input
              id="username"
              type={"text"}
              className="form-control"
              placeholder="eg forename.surname"
              style={{ borderRadius: "5px 0px 0px 5px", height: "40px"}}
              onChange={handleChange}
              value={loginHint}
            ></input>
          </div>
          <div className="col-sm-5 p-0">
            <input
              id="emailAddress"
              type={"text"}
              className="form-control"
              value={"@northumbria-healthcare.nhs.uk"}
              readOnly
              style={{ textAlign: "left", borderRadius: "0px 0px 0px 0px", height: "40px"}}
            ></input>
          </div>
          <div className="col-auto ps-0">
            <button
              id="login"
              className="btn btn-primary"
              style={{ borderRadius: "0px 5px 5px 0px", height: "40px"}}
              onClick={() => signIn(loginHint)}
            >
              Login
            </button>
          </div>
      </form>
    </div>
  );
};
