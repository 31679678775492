import { withNavigationWatcher } from "./contexts/navigation";
import { HomePage, DiaryPage, ListPage, ListHome, AuditHome, AuditPage } from "./pages";

const routes = [
  {
    path: "/home",
    component: HomePage,
  },
  {
    path: "/diary/:diaryId",
    component: DiaryPage,
  },
  {
    path: "/lists/:diaryId",
    component: ListPage,
  },
  {
    path: "/listshome/",
    component: ListHome,
  },
  {
    path: "/audit/:diaryId/:phrase",
    component: AuditPage,
  },
  {
    path: "/audithome/",
    component: AuditHome,
  }
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
