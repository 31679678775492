import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import useFetch from "../../hooks/useFetch";

import "./listshome.scss";
import notify from "devextreme/ui/notify";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { useLoading } from "../../hooks/LoadingContext";

export default function ListHome() {
  const { apiFetch } = useFetch();
  const [diaryList, setDiaryList] = useState();
  const [error, setError] = useState(false);
  const { loading, setLoading } = useLoading(false);

  useEffect(() => {
    getAllDiaries();
  }, []);

  const getAllDiaries = async () => {
    setLoading(true);
    const result = await apiFetch("get-diaries");

    if (result) {
      setDiaryList(result);
      setLoading(false);
    } else {
      setError(true);
      showToast(
        "Error",
        `Error.. unable to retrieve diary list from API please try again later...`,
        "error"
      );
      setLoading(false);
    }
  };

  const showToast = (event, value, type) => {
    notify(`${event} "${value}"`, type, 7500);
  };

  return (
    <>
      <h2 className={"content-block"}>List Home</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"logos-container"}>
            <h5>Diary List Home page</h5>
            {loading ? (
              <>
                <p>Please wait while we check the diary list.</p>
                <div className="loading-home">
                  <div className="loader-icon-home">
                    <LoadIndicator
                      id="small-indicator"
                      height={30}
                      width={30}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {error ? (
                  <div className={"info"}>
                    <p>Error loading diary list</p>
                  </div>
                ) : (
                  <>
                    {diaryList && (
                      <>
                        {diaryList && diaryList.length > 0 ? (
                          <div className={"content-block"}>
                            <p>Please pick the diary list from below</p>
                            <p>
                              If you require access to another admissions diary
                              please log a call with service desk with the
                              desired admissions diary you require.
                            </p>
                            <div className={"tile-group"}>
                              {diaryList.map((diary) => {
                                return (
                                  <Link
                                    key={diary.id}
                                    to={`/lists/${diary.id}`}
                                    className={"card"}
                                  >
                                    <div>
                                      <div className={"card-title"}>
                                        <i className="dx-icon-fields"></i>
                                      </div>
                                      <div className={"card-body"}>
                                        {diary.name}
                                      </div>
                                    </div>
                                  </Link>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <div className={"content-block"}>
                            <p>
                              No diary lists found..
                              <br />
                              <br />
                              If you require access to another admissions diary
                              list please log a call with service desk with the
                              desired admissions diary list you require.
                            </p>
                            <div className={"tile-group"}></div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
