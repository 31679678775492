import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
//import { SingleCard } from "./layouts";
import { Footer } from "./components";
import { LoggedInWithRoleAccess } from "./components/roleAccess/loggedInWithRoleAccess";
import { SignInButton } from "./components/SignInButton";
import { UnauthenticatedTemplate } from "@azure/msal-react";

export default function Content() {
  return (
    <SideNavBarLayout title={appInfo.title}>
      <LoggedInWithRoleAccess
        roles={["User"]}
        error={
          <>
            <h2 className={"content-block"} style={{textAlign: "center"}}>Access Denied</h2>
            <div className={"content-block"}>
              <p className={"signIn-loginCon"}>
                You do not have sufficient access rights. Please submit a
                request via the Digital Workplace portal to gain access to the
                diary required, approval is required from your line manager.
              </p>
            </div>
          </>
        }
      >
        <Switch>
          {routes.map(({ path, component }) => (
            <Route exact key={path} path={path} component={component} />
          ))}
          <Redirect to={{pathname: "/home"}} />
        </Switch>
      </LoggedInWithRoleAccess>
      <UnauthenticatedTemplate>
        
          <div className="container bg-white p-4">
            <SignInButton/>
          </div>
      </UnauthenticatedTemplate>
      <Footer>
        Copyright © 2021-{new Date().getFullYear()} {appInfo.title} Inc.
        <br />
        All trademarks or registered trademarks are property of their respective
        owners.
      </Footer>
    </SideNavBarLayout>
  );
}
