import React from "react";
import "./user-panel.scss";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "../SignInButton";
import { SignOutButton } from "../SignOutButton";

export default function UserPanel() {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  const name = accounts[0] && accounts[0].name;
  return (
    <>
      <div className={"user-panel"}>
        <div className={"user-info"}>
        {isAuthenticated ? 
          <> <div className={"user-name"}>{name}</div>
          <div className={"image-container"}>
            <div className={"user-image"}>
              <i className="dx-icon-user"></i>
            </div>
          </div> </>: ""}
        </div>
      </div>
      <div className={"signIn-panel"}>
        <div className={"signIn-con"}>
          {isAuthenticated ? <SignOutButton /> : ""}
        </div>
      </div>
    </>
  );
}
