export const restrictedTimes = [
  "T11:15:00.000Z",
  "T11:45:00.000Z",
  "T12:15:00.000Z",
  "T12:45:00.000Z",
  "T13:15:00.000Z",
  "T13:45:00.000Z",
  "T14:15:00.000Z",
  "T14:45:00.000Z",
  "T15:15:00.000Z",
  "T15:45:00.000Z",
  "T16:15:00.000Z",
  "T16:45:00.000Z",
];

export const restrictedTimesWeekend = [
  "T07:15:00.000Z",
  "T07:45:00.000Z",
  "T08:15:00.000Z",
  "T08:45:00.000Z",
  "T09:15:00.000Z",
  "T09:45:00.000Z",
  "T10:15:00.000Z",
  "T10:45:00.000Z",
  "T11:15:00.000Z",
  "T11:45:00.000Z",
  "T12:15:00.000Z",
  "T12:45:00.000Z",
  "T13:15:00.000Z",
  "T13:45:00.000Z",
  "T14:15:00.000Z",
  "T14:45:00.000Z",
  "T15:15:00.000Z",
  "T15:45:00.000Z",
  "T16:15:00.000Z",
  "T16:45:00.000Z",
];
